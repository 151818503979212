import posthog, { Survey } from 'posthog-js'
import { useEffect, useRef, useState } from 'react'
import Button from './ui/button'
import { ChatBot } from '~/icons/chat-bot'
import DialogModal from './ui/dialog-modal'
import { feedbackForm } from '~/validation'
import { usePosthogLoaded } from '~/providers/posthog-provider'
import ValidatedForm from './ui/validated-form'
import TextAreaInput from './ui/textarea-input'

// note patched posthog per this issue https://github.com/PostHog/posthog-js/issues/908
// import { usePostHog } from 'posthog-js/react'

export default function Feedback() {
	const posthogLoaded = usePosthogLoaded()
	const [survey, setSurvey] = useState<Survey | null>()
	const [open, setOpen] = useState(false)
	const [sent, setSent] = useState(false)
	const [wasConfirmed, setWasConfirmed] = useState(false)
	const message = useRef<string>('')

	useEffect(() => {
		if (posthogLoaded) {
			posthog.getSurveys(surveys => {
				const feedbackSurvey = surveys.filter(
					survey => survey.name === 'Product feedback'
				)[0]
				if (feedbackSurvey) {
					setSurvey(feedbackSurvey)
				}
			}, false)
		}
	}, [posthogLoaded])

	if (!survey) {
		return null
	}

	const submit = (data: any) => {
		posthog.capture('survey sent', {
			$survey_id: survey.id,
			$survey_name: survey.name,
			$survey_response: data.toString()
		})
		setSent(true)
		setWasConfirmed(true)
	}

	if (!open) {
		return (
			<div className='fixed bottom-4 right-4 z-[4000]'>
				<Button
					$size='medium'
					$style='secondary'
					rootClassName='relative rounded-[24px] w-12 h-12'
					className='!rounded-[24px] !p-2'
					onClick={() => {
						setOpen(true)
					}}
				>
					<ChatBot />
				</Button>
			</div>
		)
	}

	return (
		<DialogModal
			panelClassName='fixed !top-auto !left-auto !bottom-[5rem] !right-4 !max-w-[360px] w-full'
			panelWrapperClassName='!p-0 !justify-end'
			panelDepth={0}
			isOpen={open}
			disableOverlay
			title={survey.name}
			hideCloseButton
			onPrimary={() => {
				if (!sent) {
					submit(message.current)
				}
			}}
			onClose={path => {
				if (wasConfirmed) {
					setOpen(false)
				}

				if (path === 'secondary') {
					setOpen(false)
				}
			}}
			primaryText={survey.questions[0].buttonText ?? 'Send'}
			primaryStyle={'primary'}
			primaryDisabled={sent}
			secondaryText={'Cancel'}
			secondaryStyle={'secondary'}
		>
			<div className='fixed bottom-[-4rem] right-0 z-[999]'>
				<Button
					$size='medium'
					$style='secondary'
					rootClassName='relative !rounded-[24px] w-12 h-12'
					className='!rounded-[24px] !p-2'
					onClick={() => {
						setOpen(false)
					}}
				>
					<ChatBot />
				</Button>
			</div>
			{!sent ? (
				<ValidatedForm
					formId='feedback-form'
					validator={feedbackForm}
					successTitle='a'
					successMessage='a'
				>
					{() => (
						<TextAreaInput
							autoFocus
							label='Comments'
							name='message'
							layer={2}
							defaultValue={''}
							placeholder={'Enter a message'}
							onChange={value => {
								message.current = value
								console.log('message', message.current)
							}}
						/>
					)}
				</ValidatedForm>
			) : (
				<div className='min-h-[10rem]'>
					<p>Your feedback has been sent.</p>
					<p>Thank you!</p>
				</div>
			)}
		</DialogModal>
	)
}
