export function ChatBot({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g clipPath='url(#clip0_3924_52519)'>
				<path
					d='M10.0001 11.875C9.2782 11.8756 8.56739 11.6973 7.93121 11.3561C7.29503 11.0148 6.75332 10.5213 6.35449 9.91954L7.39574 9.22742C7.68085 9.65715 8.06787 10.0097 8.52229 10.2535C8.97671 10.4974 9.48441 10.625 10.0001 10.625C10.5158 10.625 11.0235 10.4974 11.4779 10.2535C11.9324 10.0097 12.3194 9.65715 12.6045 9.22742L13.6457 9.91954C13.2469 10.5213 12.7052 11.0148 12.069 11.3561C11.4328 11.6973 10.722 11.8756 10.0001 11.875Z'
					fill='white'
					style={{
						fill: 'white',
						fillOpacity: 1
					}}
				/>
				<path
					d='M12.5 5C12.2528 5 12.0111 5.07331 11.8055 5.21066C11.6 5.34801 11.4398 5.54324 11.3452 5.77164C11.2505 6.00005 11.2258 6.25139 11.274 6.49386C11.3223 6.73634 11.4413 6.95907 11.6161 7.13388C11.7909 7.3087 12.0137 7.42775 12.2561 7.47598C12.4986 7.52421 12.7499 7.49946 12.9784 7.40485C13.2068 7.31024 13.402 7.15002 13.5393 6.94446C13.6767 6.7389 13.75 6.49723 13.75 6.25C13.7516 6.0854 13.7204 5.92212 13.6582 5.76973C13.5959 5.61735 13.5039 5.4789 13.3875 5.3625C13.2711 5.24611 13.1327 5.15409 12.9803 5.09185C12.8279 5.0296 12.6646 4.99838 12.5 5Z'
					fill='white'
					style={{
						fill: 'white',
						fillOpacity: 1
					}}
				/>
				<path
					d='M7.5 5C7.25277 5 7.0111 5.07331 6.80554 5.21066C6.59998 5.34801 6.43976 5.54324 6.34515 5.77164C6.25054 6.00005 6.22579 6.25139 6.27402 6.49386C6.32225 6.73634 6.4413 6.95907 6.61612 7.13388C6.79093 7.3087 7.01366 7.42775 7.25614 7.47598C7.49861 7.52421 7.74995 7.49946 7.97835 7.40485C8.20676 7.31024 8.40199 7.15002 8.53934 6.94446C8.67669 6.7389 8.75 6.49723 8.75 6.25C8.75162 6.0854 8.7204 5.92212 8.65815 5.76973C8.59591 5.61735 8.50389 5.4789 8.3875 5.3625C8.2711 5.24611 8.13265 5.15409 7.98027 5.09185C7.82788 5.0296 7.6646 4.99838 7.5 5Z'
					fill='white'
					style={{
						fill: 'white',
						fillOpacity: 1
					}}
				/>
				<path
					d='M11.0849 18.7501L10 18.1251L12.5 13.7501H16.25C16.4142 13.7503 16.5769 13.7182 16.7287 13.6555C16.8805 13.5928 17.0184 13.5007 17.1345 13.3846C17.2506 13.2684 17.3427 13.1305 17.4054 12.9787C17.4681 12.827 17.5003 12.6643 17.5 12.5001V3.75006C17.5003 3.58583 17.4681 3.42316 17.4054 3.27138C17.3427 3.11959 17.2506 2.98168 17.1345 2.86555C17.0184 2.74942 16.8805 2.65736 16.7287 2.59464C16.5769 2.53192 16.4142 2.49978 16.25 2.50006H3.75C3.58577 2.49978 3.4231 2.53192 3.27131 2.59464C3.11953 2.65736 2.98162 2.74942 2.86549 2.86555C2.74936 2.98168 2.6573 3.11959 2.59458 3.27138C2.53186 3.42316 2.49972 3.58583 2.5 3.75006V12.5001C2.49972 12.6643 2.53186 12.827 2.59458 12.9787C2.6573 13.1305 2.74936 13.2684 2.86549 13.3846C2.98162 13.5007 3.11953 13.5928 3.27131 13.6555C3.4231 13.7182 3.58577 13.7503 3.75 13.7501H9.375V15.0001H3.75C3.42168 15.0001 3.09656 14.9355 2.79322 14.8099C2.48988 14.6843 2.21426 14.5001 1.98211 14.268C1.74995 14.0358 1.5658 13.7602 1.44018 13.4568C1.31457 13.1535 1.24994 12.8284 1.25 12.5001V3.75006C1.2499 3.42173 1.3145 3.09659 1.4401 2.79324C1.5657 2.48988 1.74985 2.21424 1.98201 1.98207C2.21418 1.74991 2.48982 1.56576 2.79318 1.44016C3.09653 1.31456 3.42167 1.24996 3.75 1.25006H16.25C16.5783 1.24996 16.9035 1.31456 17.2068 1.44016C17.5102 1.56576 17.7858 1.74991 18.018 1.98207C18.2502 2.21424 18.4343 2.48988 18.5599 2.79324C18.6855 3.09659 18.7501 3.42173 18.75 3.75006V12.5001C18.7501 12.8284 18.6854 13.1535 18.5598 13.4568C18.4342 13.7602 18.2501 14.0358 18.0179 14.268C17.7857 14.5001 17.5101 14.6843 17.2068 14.8099C16.9034 14.9355 16.5783 15.0001 16.25 15.0001H13.2279L11.0849 18.7501Z'
					fill='white'
					style={{
						fill: 'white',
						fillOpacity: 1
					}}
				/>
			</g>
			<defs>
				<clipPath id='clip0_3924_52519'>
					<rect
						width='20'
						height='20'
						fill='white'
						style={{
							fill: 'white',
							fillOpacity: 1
						}}
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
