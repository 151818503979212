import clsx from 'clsx'
import { type TextInputProps } from './text-input'
import { Description, Field, Label, Textarea } from '@headlessui/react'
import { WarningFilled } from '~/icons/warnings'

export type TextAreaInputProps = {} & Omit<TextInputProps, 'type'>

/*
    TextInput is still the base here
    consider de-dup clsx or give
    textinput a render prop of textarea
*/
export default function TextAreaInput({ ...props }: TextAreaInputProps) {
	const bgColor = props.layer
		? props.layer === 1
			? 'bg-field-01'
			: 'bg-field-02'
		: 'bg-field-01'
	return (
		<Field className='flex min-h-[10rem] w-full flex-col gap-1'>
			<Label
				className={clsx(
					'text-small text-text-secondary',
					props.disabled && 'text-text-disabled',
					props.hideLabel && 'hidden'
				)}
			>
				{props.label}
			</Label>
			<div className='bg-field-01 bg-field-02'></div>
			<div className='relative flex w-full grow'>
				<Textarea
					name={props.name}
					placeholder={props.placeholder}
					disabled={props.disabled}
					readOnly={props.readOnly}
					autoFocus={props.autoFocus}
					value={props.value}
					defaultValue={props.defaultValue}
					onChange={e => props.onChange?.(e.target.value)}
					className={clsx(
						'w-full grow outline-none ring-inset',
						'block px-4 pt-[13px]',
						bgColor,
						'border-b border-border-strong-01',
						'placeholder:text-text-placeholder',
						'disabled:text-text-on-color-disabled',
						'read-only:bg-[transparent]',
						'focus-visible:!border-[transparent] focus-visible:ring-3 focus-visible:ring-border-interactive',
						props.errorMessage &&
							'!border-[transparent] ring-3 ring-error',
						props.disabled && 'disabled:!border-[transparent]'
					)}
					{...(props.register && props.register(props.name))}
				/>
				{(props.errorMessage || props.icon) && (
					<div className='absolute bottom-0 right-0 top-0 flex items-center'>
						<WarningFilled
							className={clsx(
								'text-support-error',
								!props.icon && 'mr-4',
								!props.errorMessage && 'hidden'
							)}
						/>
						{props.icon}
					</div>
				)}
			</div>
			{props.helperText && !props.errorMessage && (
				<Description
					className={clsx(
						'text-small text-text-secondary',
						props.disabled && 'text-text-disabled',
						!props.label && 'hidden'
					)}
				>
					{props.helperText}
				</Description>
			)}
			{props.errorMessage && (
				<Description
					className={clsx(
						'text-small text-text-error',
						props.disabled && 'text-text-disabled',
						!props.label && 'hidden'
					)}
				>
					{props.errorMessage}
				</Description>
			)}
		</Field>
	)
}
